import ricatti from '../../assets/ricatti.jpeg'
import cena from '../../assets/cena.jpeg'
import avila from '../../assets/avila.jpeg'
import cumino from '../../assets/cumino.jpeg'
import melgarejo from '../../assets/melgarejo.jpeg'
import pittaro from '../../assets/pittaro.jpeg'
import pausada from '../../assets/pausada.jpeg'
import barrionuevo from '../../assets/barrionuevo.jpeg'
import lesta from '../../assets/lesta.jpeg'
import garay from '../../assets/garay.jpeg'
import nayar from '../../assets/nashar.jpeg'
import bogarin from '../../assets/bogarin.jpeg' 
import torres from '../../assets/torres.jpeg' 
import garaydef from '../../assets/garaydef.jpeg' 
import liziardi from '../../assets/liziardi.jpeg' 
import palermo from '../../assets/palermo.jpeg' 
import corro from '../../assets/corro.jpeg' 
export const sections = [
  {
    items: [
      {
        id: "pittaro-miguel-angel",
        name: "Pittaro Miguel Ángel",
        role: "Intendente",
        phone: "03525 - 491212",
        email: "intendente@lagranja.gob.ar",
        address: "San Martín 598, Jesús María",
        image: pittaro,
        linkedIn: "https://www.linkedin.com/in/miguelangelpittaro",
        twitter: "https://twitter.com/miguelangelpittaro",
        showInMenu: true,
        subordinates: [
          {
            id: "cumino-lisandro",
            name: "Dr. Cumino Lisandro",
            role: "Secretario de Gobierno",
            phone: "03525 - 491456 int. 113",
            email: "sec.gobierno@lagranja.gob.ar",
            showInMenu: true,
            image: cumino,
            subordinates: [
              {
                id: "ricatti-andres",
                name: "Ricatti Andres",
                role: "Secretario de Hacienda y Finanzas",
                image: ricatti,
                phone: "03525 - 491456 int. 112",
                email: "sec.finanzas@lagranja.gob.ar",
                showInMenu: true,
              },
              {
                id: "melgarejo-claudia",
                name: "Melgarejo Claudia",
                role: "Secretaria de Turismo y Cultura",
                phone: "03525 - 491456 int. 108",
                email: "sec.turismo@lagranja.gob.ar",
                image: melgarejo,
                showInMenu: true,
                subordinates: [
                  {
                    id: "lic-garay-morena",
                    name: "Lic. Garay Morena",
                    role: "Directora de cultura",
                    phone: '03525 - 491456 int. 108',
                    image: garay,
                    email: "sec.turismo@lagranja.gob.ar",
                  },
                  {
                    id: "nayar-paula",
                    name: "Nayar Paula",
                    image: nayar,
                    role: "Directora de deporte",
                    phone: '03525 - 491456 int. 108',
                    email: "sec.turismo@lagranja.gob.ar",
                  },
                ],
              },
              {
                id: "pausada-marcelo",
                name: "Pausada Marcelo",
                image: pausada,
                role: "Secretario de Desarrollo Humano y Salud",
                phone: "03525 - 491456 int. 106",
                email: "sec.desarrollohumano@lagranja.gob.ar",
                showInMenu: true,
              },
              {
                id: "cena-fabricio",
                name: "Cena Fabricio",
                role: "Director de Obras Públicas",
                phone: "03525 - 491456 int. 110",
                email: "obraspublicas@lagranja.gob.ar",
                showInMenu: true,
                image: cena,
                subordinates: [
                  {
                    id: "palermo-jorge",
                    name: "Palermo Jorge",
                    image: palermo,
                    role: "Coordinador de Servicios Públicos",
                    phone: '03525 - 491456 int. 110',
                    email: "serviciospublicos@lagranja.gob.ar",
                  },
                ],
              },
              {
                id: "barrionuevo-daniela",
                name: "Barrionuevo Daniela",
                image: barrionuevo,
                role: "Directora del RRHH",
                phone: "03525 - 491456 int. 107",
                email: "rrhh@lagranja.gob.ar",
                showInMenu: true,
              },
              {
                id: "telmo-albrech",
                name: "Lic. Torres Veronica ",
                image: torres,
                role: "Directora centro de Salud",
                phone: "03525 - 491456 int. 102",
                email: "caps@lagranja.gob.ar",
                showInMenu: true,
              },
              {
                id: "avila-stella",
                name: "Arq. Ávila Stella",
                image: avila,
                role: "Área Obras Privadas",
                phone: "03525 - 491456 int. 109",
                email: "obrasprivadas@lagranja.gob.ar",
              },
              {
                id: "del-corro-maria",
                name: "Ing. Del Corro María del Carmen",
                image: corro,
                role: "Catastro",
                phone: "03525 - 491456 int. 101",
                email: "catastro@lagranja.gob.ar",
              },
              {
                id: "bogarin-romina",
                name: "Bogarín Romina",
                image: bogarin,
                role: "Área de Gestión de Cobranza",
                phone: "03525 - 491456 int. 114",
                email: "gestioncobranza@lagranja.gob.ar",
              },
              {
                id: "garay-mario",
                name: "Garay Mario",
                image: garaydef,
                role: "Def. Civil / Seguridad Ciudadana",
                phone: "03525 - 491212",
                email: "seguridad@lagranja.gob.ar",
              },
              {
                id: "calioga-diana",
                name: "Geol. Espec. En Ing. Ambiental Allievi Diana Elizabeth",
                role: "Directora de Ambiente",
                phone: "03525 - 491212",
                email: "ambiente@lagranja.gob.ar",
                subordinates: [
                  {
                    id: "lizarde-fabiana",
                    name: "Dvm. Liziardi Fabiana",
                    image: liziardi,
                    role: "Área de Zoonosis",
                    phone: "03525 - 491212",
                    email: "zoonosis@lagranja.gob.ar",
                  },
                ],
              },
              {
                id: "alexis-lesta",
                name: "Lesta Alexis",
                role: "Coordinador de Transporte",
                phone: "03525 - 434290",
                email: "munibus@lagranja.gob.ar",
                image: lesta,
              },
            ],
          },
        ],
      },
    ],
  },
];